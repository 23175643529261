import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" // eslint-disable-line

export const useSiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(graphql`
    query {
      sanitySiteConfig {
        addresses {
          heading: locationHeading
          text: _rawLocationInfo
        }
        loginLink
        applyLink
        mainNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
          ... on SanityExternalLink {
            _type
            ...ExternalLinkFields
          }
        }
        footerNav {
          ... on SanityPageLink {
            _type
            ...PageLinkFields
          }
          ... on SanityPathLink {
            _type
            ...PathLinkFields
          }
          ... on SanityExternalLink {
            _type
            ...ExternalLinkFields
          }
        }
      }
    }
  `);
  return sanitySiteConfig || {}
}

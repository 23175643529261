import React from "react"

import { AnimatePresence, motion } from "framer-motion"

import "../css/main.css"

import { useLocation } from "@reach/router"

import { Header } from "../components/nav/header"

import { Footer } from "../components/nav/footer"

const Layout = ({ children }) => {
  const { pathname } = useLocation()
  return (
    <>
      <Header />
      <AnimatePresence>
        <motion.main
          className="w-full absolute top-0 left-0 antialiased font-medium font-body text-gray tracking-global"
          key={pathname}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            ease: 'easeInOut',
            duration: 0.3,
          }}
        >
          {children}          
          <Footer />
        </motion.main>
      </AnimatePresence>
    </>
  );
}

export default Layout

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { AppWrapper } from './src/state/appState';

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <AppWrapper>{element}</AppWrapper>
    </>
  );
};

const transitionDelay = 300;

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else if (location.action !== 'POP') {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay);
  }
  return false;
};


// const addScript = (url) => {
//   const script = document.createElement('script');
//   script.src = url;
//   script.async = true;
//   document.body.appendChild(script);
// };

// export const onClientEntry = () => {
//   window.onload = () => {
//     addScript('https://cdn.weglot.com/weglot.min.js');
//     addScript('/script.js');
//   };
// };   

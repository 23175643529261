import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

import { useScrollFreeze } from '../../hooks';

import { CapitalForm } from "../forms/capitalForm"

import { Close } from '../ui';

// Animation Variants
const navWrapper = {
  closed: {
    y: '100vh',
    transition: {
      duration: 0.5,
      delay: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    y: '0',
    transition: {
      duration: 0.7,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
};

const formWrapper = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96],
    },
  },
  open: {
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.7,
    },
  },
};

const ModalInner = ({ form, setOpen }) => {
  useScrollFreeze();
  return (
    <motion.div
      className="fixed w-full bg-brand z-9999 top-0 left-0 right-0 bottom-0 text-white px-gutter py-12 lg:py-20 overflow-y-scroll 2xl:flex 2xl:flex-col 2xl:justify-center"
      variants={navWrapper}
      initial="closed"
      animate="open"
      exit="closed"
    >
      <div className="w-full flex justify-between items-center border-b border-white pb-12 mb-12">
        <h2 style={{color:'white'}} className=" font-headline text-2xl lg:text-3xl">Enquire Now</h2>
        <div className="cursor-pointer" onClick={() => setOpen(false)}>
          <Close />
        </div>
      </div>
      <motion.div
        variants={formWrapper}
        initial="closed"
        animate="open"
        exit="closed"
        className="w-full"
      >
        {form === 'capital' && <CapitalForm />}
      </motion.div>
    </motion.div>
  );
};
export const Modal = ({ isOpen, setOpen, form }) => {
  return (
    <AnimatePresence>{isOpen && <ModalInner form={form} setOpen={setOpen} />}</AnimatePresence>
  );
};

import React from 'react';
import { Modal } from './modal';

import { useToggle } from '../../hooks';

export const FormButton = ({ className, buttonText, selectedForm, ...rest }) => {

  const [isOpen, setOpen] = useToggle(false); 

  return (
    <>
      <div
        rel="noreferrer"
        className="inline-block cursor-pointer px-8 py-4 bg-brand text-white font-display rounded-full"
        onClick={() => setOpen(true)}
      >
        {buttonText && buttonText}
      </div>
      <Modal isOpen={isOpen} setOpen={setOpen} form={selectedForm} />
    </>
  );
};

import React from 'react'
import Image from "gatsby-plugin-sanity-image"
import { Link } from 'gatsby';

import { useSanityPath } from '../../hooks';

import { MultiLink } from '../ui/links';

const CardLink = ({ link, children }) => {

  const path = useSanityPath(link[0].page);

  return (
    <>
      {link[0]._type === 'pageLink' ? (
        <Link to={path}>
          {children}
        </Link>
      ) : (
        <a href={link[0].link} target="_blank" rel="noreferrer">
          {children}
        </a>
      )}
    </>
  );
}
export const Card = ({ heading, caption, link, image, logo }) => {
  
  return (
    <div className="col-span-1">
      {image && (
        <CardLink link={link}>
          <div className="block relative w-full aspect-w-3 aspect-h-2">
            <Image className="absolute inset-0 object-cover" {...image} />
          </div>
        </CardLink>
      )}

      <div className="">
        {logo ? (
          <Image {...logo} className="h-6 w-auto mt-10 mb-4" />
        ) : (
          <CardLink link={link}>
            <h4 className="text-brand font-display text-xl lg:text-2xl mt-4 md:mt-10 mb-4">
              {heading && heading}
            </h4>
          </CardLink>
        )}

        <p className="mb-4 text-gray">{caption && caption}</p>
        <div className="btn-branded">
          <MultiLink {...link[0]} />
        </div>
      </div>
    </div>
  );
}

import React from 'react'

export const LogoFooter = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      width="231"
      height="29"
      viewBox="0 0 231 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.3695 29C93.2998 29 99.7286 22.5081 99.7286 14.5C99.7286 6.49187 93.2998 0 85.3695 0C77.4393 0 71.0105 6.49187 71.0105 14.5C71.0105 22.5081 77.4393 29 85.3695 29Z"
        fill="#CF2630"
      />
      <path
        d="M49.9035 29C57.8338 29 64.2626 22.5081 64.2626 14.5C64.2626 6.49187 57.8338 0 49.9035 0C41.9733 0 35.5445 6.49187 35.5445 14.5C35.5445 22.5081 41.9733 29 49.9035 29Z"
        fill="#CF2630"
      />
      <path
        d="M14.359 29C22.2893 29 28.7181 22.5081 28.7181 14.5C28.7181 6.49187 22.2893 0 14.359 0C6.42876 0 0 6.49187 0 14.5C0 22.5081 6.42876 29 14.359 29Z"
        fill="#CF2630"
      />
    </svg>
  );
};

